/* You can add global styles to this file, and also import other style files */
@use '@lucanet-main/lucanet-library-theme/styles/global' as *;
@use '@lucanet-main/lucanet-library-theme/styles/components' as *;
@use '@lucanet-main/lucanet-library-theme/styles/main' as *;
@use '@lucanet-main/lucanet-library-theme/styles/utils' as *;
@use '@lucanet-main/lucanet-library-theme/styles/visual-elements' as *;

/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Alpine Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import '~@ng-select/ng-select/themes/default.theme.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: unset;
  background-color: #f6f6f8;
  height: 100%;

  ln-spa-sh-root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @include scrollbar($color-n100, transparent);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.ln-context-menu {
  color: $color-n300;
}
